<template>
  <div>
    <div class="flex flex-col text-14 mb-5 mt-2 font-bold">
      <h2 class="text-2xl font-bold">Expense Transactions</h2>
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :data="transactions.data"
        :columns="transactions.columns"
        :filters="transactions.filters"
        :query="transactions.query"
        :loading="transactions.loading"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/expenses/transactions`"
        :search-field="searchField"
        :on-click="click"
        ref="table"
      >
        <template #header>
          <!-- <search-form
            v-model="transactions.query"
            class="mb-5"
            placeholder="Search for transactions's id, reference"
            @submit="reloadData"
          /> -->

          <div class="flex items-center mb-5">
            <template v-for="(filter, filterIndex) in transactions.filters">
              <div
                :key="filterIndex"
                class="processor-filter-item"
                :class="{ active: currentFilter === filter }"
                @click.prevent="setFilter(filter)"
              >
                {{ filter.title }}
              </div>
            </template>
          </div>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
    searchField: {
      type: [HTMLInputElement],
      default: null,
    },
  },
  data() {
    return {
      checkmark: require('@/assets/checkmark-base.svg'),
      currentFilter: null,
      transactions: this.$options.resource([], {
        query: '',
        // filters: [
        //   { name: 'all', title: 'All' },
        //   { name: 'active', title: 'Active' },
        // ],
        selected: null,
        columns: [
          {
            name: 'id',
            th: 'Id',
          },
          {
            name: 'reference',
            th: 'Reference',
          },

          {
            name: 'amount',
            th: 'Amount',
            render: (investment) =>
              `₦ ${this.$options.filters.currency(investment.amount)}`,
          },
          {
            name: 'type',
            th: 'Type',
          },
          {
            name: 'description',
            th: 'Description',
          },
          {
            name: 'retry_count',
            th: 'Retry Count',
          },
          {
            name: 'status',
            th: 'Status',
            render: (transaction, status) =>
              this.transactionstatusCheck(status),
          },
          {
            name: 'is_resolved',
            th: 'Is Resolved',
          },
          {
            name: 'created_at',
            th: 'Date',
            render: (transaction, date) =>
              this.$options.filters.dateFormat(date, 'D, dd M Y, h:ia'),
          },
        ],
      }),
      form: this.$options.basicForm(['status']),
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(defaultUser) {
      this.$router.push({
        name: 'staff-view',
        params: { staffId: defaultUser?.user_id },
      });
    },
    reloadData() {
      this.$refs.table.loadAjaxData();
    },
    setFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
    pndStatusCheck(pnd) {
      switch (pnd) {
        case 1:
          return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-2 py-1 inline-block">
                      active
                    </div>`;
        case 0:
        default:
          return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-2 py-1 inline-block">
                      inactive
                    </div>`;
      }
    },
    transactionstatusCheck(status) {
      switch (status) {
        case 'closed':
          return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-2 py-1 inline-block">
                    ${status}
                  </div>`;
        case 'liquidated':
          return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-2 py-1 inline-block">
                    ${status}
                  </div>`;
        case 'running':
        default:
          return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-2 py-1 inline-block">
                    ${status}
                  </div>`;
      }
    },
  },
};
</script>
